import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useModalContext } from 'hooks/modal-context'
import { useSearchContext } from 'hooks/search-context'

import Modal from 'shared/Modal/Modal'
import Slider from 'shared/Slider/Slider'

interface Datas {
  datas: {
    allContentfulGalleries: {
      nodes: {
        id: number
        slug: string
        images: {
          id: string
          title: string
          gatsbyImageData: any
        }[]
      }[]
    }
  }
}

const s = {
  group: `w-full h-full`,
  title: `absolute top-4 right-4 z-2 mb-0 text-2xl text-white uppercase`,
  count: `absolute top-12 right-4 z-2 mb-0 text-base text-white`,
  image: `w-full h-h75 2xlm:h-h55 lgm:h-h55 mdm:h-h50 smx:h-h25`,
}

const Image = ({ datas }: Datas) => {
  const { openModal } = useModalContext()
  const { query } = useSearchContext()

  const filtered = datas.allContentfulGalleries.nodes[0].images.filter(
    (image) => {
      if (query === ``) {
        return image
      } else if (image.title.toLowerCase().includes(query.toLowerCase())) {
        return image
      }
    }
  )

  const images = filtered.map(({ title, gatsbyImageData }, id) => (
    <div key={id} className={s.group}>
      <GatsbyImage
        className={s.image}
        image={gatsbyImageData}
        alt={title}
        loading="eager"
      />

      <p className={s.title}>{title}</p>
      <p className={s.count}>{`${id + 1} of ${filtered.length}`}</p>
    </div>
  ))

  return (
    <Modal openModal={openModal}>
      <Slider autoPlay={false}>{images}</Slider>
    </Modal>
  )
}

export default Image
