import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Image from 'main/Hero/Image'
import Content from 'main/Hero/Content'

const s = {
  container: `relative w-full h-screen`,
  wrapper: `wrapper h-full flex items-center`,
}

const Hero = () => {
  const datas = useStaticQuery(
    graphql`
      query {
        contentfulHero {
          slug
          title
          subtitle
          link
          text
          images {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              quality: 100
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    `
  )

  const { slug } = datas.contentfulHero

  return (
    <section className={s.container} id={slug}>
      <Image datas={datas} />

      <div className={s.wrapper}>
        <Content datas={datas} />
      </div>
    </section>
  )
}

export default Hero
