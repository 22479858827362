import React from 'react'
import { Link } from 'gatsby'

import { s } from 'shared/Button/_button'

interface Button {
  to: string
  classname: string
  children: any
}

const AnchorButton = ({ to, classname, children }: Button) => {
  return (
    <Link to={to} className={`${s.button} ${classname}`}>
      {children}
    </Link>
  )
}

export default AnchorButton
