import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import LinkButton from 'shared/Button/LinkButton'

interface Datas {
  datas: {
    allContentfulBlogs: {
      nodes: {
        id: number
        slug: string
        title: string
        date: string
        snippet: any
        images: {
          title: string
          gatsbyImageData: any
        }
      }[]
    }
  }
}

const s = {
  container: `w-full h-fit-content`,
  grid: `grid-3 gap-4 mdx:grid-cols-1 mdx:gap-0`,
  card: `relative w-full h-fit-content mdx:mb-20 mdx:last:mb-0`,

  link: `w-full h-full`,
  image: `w-full h-h55 rounded-xl lgm:h-h25 mdm:h-h50 smx:h-h35`,

  body: `w-full h-fit-content flex flex-col pt-12 px-4`,
  title: `mb-3 text-h4`,
  date: `mb-6 text-sm`,
  snippet: `mb-12 flex-grow text-base leading-loose text-darkgray`,
  button: `invert`,
}

const Content = ({ datas }: Datas) => {
  const posts = datas.allContentfulBlogs.nodes

  return (
    <div className={s.container}>
      <div className={s.grid}>
        {posts.map(({ id, slug, title, date, snippet, images }) => (
          <div key={id} className={s.card}>
            <Link className={s.link} to={`/post/${slug}/`} aria-label={title}>
              <GatsbyImage
                className={s.image}
                image={images.gatsbyImageData}
                alt={images.title}
                loading="eager"
              />
            </Link>
            <div className={s.body}>
              <h3 className={s.title}>{title}</h3>
              <p className={s.date}>{date}</p>
              <p className={s.snippet}>{snippet}</p>

              <LinkButton classname={s.button} to={`/post/${slug}/`}>
                Read Post
              </LinkButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Content
