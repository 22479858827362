import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Galleries/Content'
import Image from 'main/Galleries/Image'

const s = {
  container: `w-full h-fit-content py-24 section`,
  wrapper: `wrapper`,
  title: `-mb-20 mdx:mb-20`,
}

const Galleries = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulGallery {
        slug
        title
      }
      allContentfulGalleries {
        nodes {
          id
          slug
          images {
            id
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 80
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  const { slug, title } = datas.contentfulGallery

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <Content datas={datas} />
        <Image datas={datas} />
      </div>
    </section>
  )
}

export default Galleries
