import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import { useModalContext } from 'hooks/modal-context'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface Datas {
  autoPlay: boolean
  children: any
}

const Slider = ({ autoPlay, children }: Datas) => {
  const { index } = useModalContext()

  return (
    <Carousel
      className="w-full h-full carousel"
      // infiniteLoop
      interval={5000}
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      autoPlay={autoPlay}
      selectedItem={Number(index)}
    >
      {children}
    </Carousel>
  )
}

export default Slider
