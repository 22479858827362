import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useSearchContext } from 'hooks/search-context'
import { useModalContext } from 'hooks/modal-context'

import { AnimationFadeIn } from 'shared/Animation/Animation'
import Search from 'shared/Search/Search'

interface Datas {
  datas: {
    allContentfulGalleries: {
      nodes: {
        slug: string
        images: {
          id: number
          title: string
          gatsbyImageData: any
        }[]
      }[]
    }
  }
}

const s = {
  container: `w-full h-fit-content`,
  link: `w-full h-h55 text-center cursor-pointer xlx:h-h32`,
  image: `w-full h-full rounded-xl transition-all delay-500 ease-out lgx:h-h32`,
  text: `w-full h-full flex justify-center items-center`,
}

const Content = ({ datas }: Datas) => {
  const { setOpenModal, setIndex } = useModalContext()
  const { query } = useSearchContext()

  const openClick = (id: number) => {
    setIndex(id)
    setOpenModal(true)
  }

  const filtered = datas.allContentfulGalleries.nodes[0].images.filter(
    (image) => {
      if (query === ``) {
        return image
      } else if (image.title.toLowerCase().includes(query.toLowerCase())) {
        return image
      }
    }
  )

  const images = filtered.map(({ title, gatsbyImageData }, id) => (
    <AnimationFadeIn key={id} x={0} y={0} delay={300}>
      <div className={s.link} onClick={() => openClick(id)}>
        <GatsbyImage
          className={s.image}
          image={gatsbyImageData}
          alt={title}
          loading="eager"
        />
      </div>
    </AnimationFadeIn>
  ))

  return (
    <div className={s.container}>
      <Search placeholder={'Search...'}>
        {images}
        {images.length === 0 ? (
          <div className={s.text}>No images found. Try again...</div>
        ) : null}
      </Search>
    </div>
  )
}

export default Content
