import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Datas {
  datas: {
    contentfulAbout: {
      images: {
        title: string
        gatsbyImageData: any
      }
    }
  }
}

const styles = {
  image: `w-full h-full rounded-xl`,
}

const AboutImage = ({ datas }: Datas) => {
  const { images } = datas.contentfulAbout

  return (
    <GatsbyImage
      className={styles.image}
      image={images.gatsbyImageData}
      alt={images.title}
      loading="eager"
    />
  )
}

export default AboutImage
