import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useSearchContext } from 'hooks/search-context'

interface Datas {
  placeholder: string
  children: any
}

const s = {
  container: `w-full h-fit-content`,
  flex: `w-1/2 flex flex-row items-center mb-20 ml-auto hover:shadow-lg transition-all delay-300 ease-out duration-200 mdx:w-full`,
  input: `w-full py-4 px-4 bg-white shadow-sm focus:outline-none`,
  button: `w-16 h-16 flexcenter bg-white shadow-sm cursor-default`,
  image: `w-7 h-7`,
  grid: `grid-3 gap-4 mdm:grid-2 smm:grid-2 xsx:grid-cols-1`,
}

const Search = ({ placeholder, children }: Datas) => {
  const { setQuery } = useSearchContext()

  const datas = useStaticQuery(graphql`
    query {
      contentfulSearch {
        images {
          title
          file {
            details {
              image {
                width
                height
              }
            }
            url
          }
        }
      }
    }
  `)

  const { images } = datas.contentfulSearch

  const handleChange = (e: any) => {
    setQuery(e.target.value)
  }

  return (
    <section className={s.container}>
      <div className={s.flex}>
        <input
          className={s.input}
          placeholder={placeholder}
          onChange={handleChange}
        />
        <button className={s.button}>
          <img
            className={s.image}
            src={images.file.url}
            alt={images.title}
            width={images.file.details.image.width}
            height={images.file.details.image.height}
          />
        </button>
      </div>

      <div className={s.grid}>{children}</div>
    </section>
  )
}

export default Search
