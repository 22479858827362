import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Post/Content'

const s = {
  container: `w-full h-fit-content pt-24 pb-48 section`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-20`,
}

const Post = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulBlog {
        slug
        title
      }
      allContentfulBlogs(sort: { fields: date, order: DESC }) {
        nodes {
          id
          slug
          title
          date(formatString: "DD MMMM YYYY")
          snippet
          images {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              quality: 80
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  const { slug, title } = datas.contentfulBlog

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <Content datas={datas} />
      </div>
    </section>
  )
}

export default Post
