import React from 'react'
import Markdown from 'markdown-to-jsx'

import AnchorButton from 'shared/Button/AnchorButton'

interface Datas {
  datas: {
    contentfulAbout: {
      title: string
      description: any
      link: string
      text: string
    }
  }
}

const s = {
  content: `w-full h-fit-content text-black`,
  title: `-mt-4 mb-12`,
  description: `w-full mb-20 text-base leading-loose description`,
  button: ` block invert`,
}

const Content = ({ datas }: Datas) => {
  const { title, description, link, text } = datas.contentfulAbout

  return (
    <div className={s.content}>
      <h2 className={s.title}>{title}</h2>

      <Markdown className={s.description}>{description.description}</Markdown>

      <AnchorButton classname={s.button} to={`/${link}`}>
        {text}
      </AnchorButton>
    </div>
  )
}

export default Content
