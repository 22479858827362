import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/About/Content'
import Image from 'main/About/Image'

const s = {
  container: `w-full h-fit-content py-24 mt-24 section`,
  wrapper: `wrapper`,
  grid: `grid-2 items-center gap-20 mdx:grid-cols-1`,
}

const About = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulAbout {
        slug
        title
        description {
          description
        }
        link
        text
        images {
          title
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  const { slug } = datas.contentfulAbout

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <div className={s.grid}>
          <Image datas={datas} />
          <Content datas={datas} />
        </div>
      </div>
    </section>
  )
}

export default About
