import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Seo from 'shared/Seo/Seo'
import Layout from 'shared/Layout/Layout'
import Hero from 'main/Hero/Hero'
import About from 'main/About/About'
import Galleries from 'main/Galleries/Galleries'
import Post from 'main/Post/Post'

const IndexPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      contentfulSeo {
        title
        description
      }
    }
  `)

  const { title, description } = datas.contentfulSeo

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Hero />
      <About />

      <Galleries />

      <Post />
    </Layout>
  )
}

export default IndexPage
