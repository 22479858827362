import React from 'react'

import { useModalContext } from 'hooks/modal-context'

interface Modals {
  openModal: boolean
  children: any
}

const s = {
  container: (open: any) => `
  ${open ? `opacity-100 visible` : `opacity-0 invisible`}
  fixed top-0 left-0 z-99 w-full h-full flexcenter bg-opblack  
  transition-all delay-300 ease-out duration-300`,

  group: `w-7/12 h-h95p pt-0 px-4 pb-4 rounded bg-white 
  xlm:w-9/12 lgm:w-10/12 mdm:w-11/12 smm:w-9/12 xsm:w-11/12`,
  header: `w-full h-fit-content flexbetween py-4 `,
  button: `my-0 mr-2 ml-auto text-lg border-0 bg-transparent curser-pointer`,
  body: `w-full h-h95p transition-all delay-500 ease-in-out`,
}

const Modal = ({ openModal, children }: Modals) => {
  const { setOpenModal } = useModalContext()

  const closeClick = () => setOpenModal(false)

  return (
    <section className={s.container(openModal)}>
      <div className={s.group}>
        <div className={s.header}>
          <button className={s.button} onClick={() => closeClick()}>
            Close
          </button>
        </div>
        <div className={`${s.body}`}>{children}</div>
      </div>
    </section>
  )
}

export default Modal
