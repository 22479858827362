import React from 'react'

import { AnimationTrail } from 'shared/Animation/Animation'
import AnchorButton from 'shared/Button/AnchorButton'

interface Datas {
  datas: {
    contentfulHero: {
      title: string
      subtitle: string
      link: string
      text: string
    }
  }
}

const s = {
  content: `relative z-2 w-w65 h-fit-content flex flex-col text-white 
  mdm:w-w55 smm:w-w55 smm:mt-20 xsx:w-full`,
  subtitle: `-mb-4 text-clamp-3xl smx:-mb-0`,
  title: `mb-20  smx:mb-16`,
}

const Content = ({ datas }: Datas) => {
  const { title, subtitle, link, text } = datas.contentfulHero

  return (
    <div className={s.content}>
      <AnimationTrail delay={1100} x={0} y={100}>
        <p className={s.subtitle}>{subtitle}</p>
        <h1 className={s.title}>{title}</h1>
        <AnchorButton classname="" to={`/${link}`}>
          {text}
        </AnchorButton>
      </AnimationTrail>
    </div>
  )
}

export default Content
